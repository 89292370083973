import { Controller } from "@hotwired/stimulus";

import $ from "jquery";

export default class extends Controller {
  static targets = ["modal"];

  open(event) {
    const { applicantName, formAction } = event.params;

    this.modalTarget.querySelector(".modal-title").innerText = `Reject ${
      applicantName || "this applicant"
    }?`;
    this.modalTarget.querySelector("form").action = formAction;

    $(this.modalTarget).modal("show");
  }
}
