import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "addButton",
    "searchTermFields",
    "sectorField",
    "termTemplate",
  ];

  addSearchTerms(event) {
    event.preventDefault();

    const content = this.termTemplateTarget.innerHTML.replace(
      /NEW_RECORD/g,
      new Date().getTime()
    );
    this.searchTermFieldsTarget.insertAdjacentHTML("beforeend", content);
  }

  sectorFilterTypeChanged(event) {
    const filterEnabled = event.target.checked;

    this.sectorFieldTargets.forEach((field) => {
      field.disabled = !filterEnabled;
    });
  }

  removeSearchTerms(event) {
    event.preventDefault();

    event.target.closest(".form-row").remove();
  }
}
