import { Controller } from "@hotwired/stimulus";
import $ from "jquery";

export default class extends Controller {
  connect() {
    // This is a reimplementation of BS3 tab functionalitly, just so we can call toggleHorizontalScrollIndicator when a new tab is loaded ----------------
    $(".tab-control a").click(function (e) {
      e.preventDefault();

      $("#sidebar-nav-tabs")
        .children()
        .each(function () {
          $(this).find("a").removeClass("active");
        });

      $(this).tab("show");

      //Runs once new tables are visible
      toggleHorizontalScrollIndicator();
    });

    $("#sidebar-nav-tabs a:first").tab("show");

    // The 'Next' pager element ----------------
    $(".segmented-survey .next-page").click(function () {
      $("#sidebar-nav-tabs li > .active")
        .parent()
        .next("li")
        .find("a")
        .trigger("click");
    });

    // The 'Prev' pager element -----------------
    $(".segmented-survey .previous-page").click(function () {
      $("#sidebar-nav-tabs li > .active")
        .parent()
        .prev("li")
        .find("a")
        .trigger("click");
    });

    // Custom File Upload buttons ----------------
    // https://tympanus.net/codrops/2015/09/15/styling-customizing-file-inputs-smart-way/

    $(".inputfile").each(function () {
      var $input = $(this),
        $label = $input.next("label"),
        labelVal = $label.html();

      $input.on("change", function (e) {
        var fileName = "";

        if (e.target.value) fileName = e.target.value.split("\\").pop();

        if (fileName) $label.find("span").html(fileName);
        else $label.html(labelVal);
      });
    });

    // Handle unsaved changes and confirm before leaving page ---------------
    var isDirty = false;

    $(
      "body.answer_groups_traditional form input, body.answer_groups_traditional form textarea"
    ).change(function () {
      isDirty = true;
      $(".unsaved-changes").html("You Have Unsaved Changes.");
    });

    $(window).bind("beforeunload", function () {
      if (isDirty) return "Unsaved changes will be lost if you leave the page.";
    });

    $("button[type='submit'], input[type='submit']").click(function () {
      $(window).unbind("beforeunload");
    });

    //Runs on load after tabs are setup
    toggleHorizontalScrollIndicator();
  }
}

function toggleHorizontalScrollIndicator() {
  $(".table-responsive").each(function () {
    $(this).prevAll(".scrollme").remove();
    if (hasScroll(this, "x"))
      $(this).before(
        "<span class='scrollme'><i class='fa fa-arrows-h'></i></span>"
      );
  });
}

// Detect presence of scrollbar (element must be visible for this to work)
function hasScroll(selector, axis) {
  var e = $(selector),
    fn = axis == "x" ? "scrollLeft" : "scrollTop";
  return e[fn](1) && e[fn]() > 0 && e[fn](0) && true;
}
